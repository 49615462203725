// composable to detect screen size
export const useScreenSize = () => {
  const width = ref(0)

  // Tailwind default breakpoints
  const breakpoints = {
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    '2xl': 1536,
  }

  const updateWidth = () => {
    width.value = window.innerWidth
  }

  const isMobile = computed(() => width.value < breakpoints.md)
  const isTablet = computed(
    () => width.value >= breakpoints.md && width.value < breakpoints.lg
  )
  const isDesktop = computed(() => width.value >= breakpoints.lg)

  // Specific breakpoint checks
  const isSm = computed(() => width.value >= breakpoints.sm)
  const isMd = computed(() => width.value >= breakpoints.md)
  const isLg = computed(() => width.value >= breakpoints.lg)
  const isXl = computed(() => width.value >= breakpoints.xl)
  const is2Xl = computed(() => width.value >= breakpoints['2xl'])

  onMounted(() => {
    if (process.client) {
      width.value = window.innerWidth
      window.addEventListener('resize', updateWidth)
    }
  })

  onUnmounted(() => {
    if (process.client) {
      window.removeEventListener('resize', updateWidth)
    }
  })

  return {
    width,
    isMobile,
    isTablet,
    isDesktop,
    isSm,
    isMd,
    isLg,
    isXl,
    is2Xl,
    breakpoints,
  }
}
