<template>
  <div ref="productShowcase" class="kft-product-showcase">
    <ErrorBoundary>
      <ProductsLoader
        v-show="loading || !isProductsReady"
        :is-horizontal="mobileHorizontalScroll"
      />

      <div v-show="!loading && isProductsReady" class="flex flex-col">
        <!-- Begin::Title -->
        <Heading
          v-if="title"
          :h-class="titleHClass || `h${titleLevel}`"
          :level="titleLevel"
          :title="title"
          :use-h-tag="useHTag"
          class="mb-8 mb-md-4 kft-product-showcase__heading kft-heading--left"
        />
        <!-- End::Title -->

        <component
          :is="productShowcaseContainer"
          class="product-showcase-cards-container"
          data-test-id="search-results"
        >
          <template
            v-for="(item, i) in products"
            :key="`kft-search-product-card-${item.objectID}`"
          >
            <component
              :is="productCard"
              :class="{
                flex: !isSlider,
                'slider-item': isSlider,
                hidden: i + 1 > showItemsOnDesktop && isCollapsed,
              }"
            >
              <transition name="fade-slide-up">
                <!-- using v-show for animation effect on homepage -->
                <!-- probably can be fully removed with <transition> -->
                <ProductCard
                  v-show="
                    isMobile ||
                    !isCollapsed ||
                    (i < showItemsOnDesktop && isCollapsed)
                  "
                  v-bind="productCardProps(item, i)"
                  :is-slide="isProductCardSlide"
                  handle-click
                  @click="fnHandleClickOnCard(item, i)"
                />
              </transition>
            </component>

            <ProductCard
              v-if="i === 0 && specialGiftcardEvent"
              v-bind="productGiftcardProps(specialGiftcardEvent)"
              :is-slide="isProductCardSlide"
            />

            <template v-for="(position, index) in promotionSlotIndices">
              <slot v-if="i === position" :name="`promotion${index + 1}`" />
            </template>
          </template>
        </component>

        <Button
          v-if="products.length > 4 && isDesktopAccordeon"
          class="show-more-button"
          @click="toggleShowMore"
          >{{
            isCollapsed
              ? $t('product.showcase.showMore')
              : $t('product.showcase.showLess')
          }}
        </Button>
      </div>
      <!-- End::Product List -->
    </ErrorBoundary>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { type AlgoliaEventItem } from '~/composables/types/algolia'
import { sendViewItemList } from '~/composables/useGtmEvents'
import { useSendEventToAlgolia } from '~/composables/product/useProduct'

const uid = useId()
const router = useRouter()
const route = useRoute()
const { t, locale } = useI18n()

const { isMobile } = useScreenSize()

interface Props {
  title?: string
  queryId?: string | string[]
  titleLevel?: number
  titleHClass?: string
  tags?: any[]
  products?: any[]
  imageLoading?: '' | 'lazy' | 'eager'
  promotionSlotIndices?: number[]
  loading?: boolean
  disableSendEventToAlgolia?: boolean
  algoliaInsightsLabel?: string
  gtmItemListId?: string | null
  algoliaInsightsClient?: object | void | null
  giftCardEvent?: boolean
  showItemsOnDesktop?: number
  useHTag?: boolean
  specialGiftcardEvent?: object
  isDesktopAccordeon?: boolean
  isMobileSlider?: boolean
  isDurationVisible?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  title: '',
  queryId: '',
  titleLevel: 2,
  titleHClass: 'text-h3 font-semibold',
  tags: () => [],
  products: () => [],
  imageLoading: 'lazy',
  promotionSlotIndices: () => [1, 9],
  loading: false,
  disableSendEventToAlgolia: false,
  algoliaInsightsLabel: '',
  gtmItemListId: null,
  algoliaInsightsClient: () => ({}),
  giftCardEvent: false,
  mobileHorizontalScroll: false,
  showItemsOnDesktop: 4, // works together with isDesktopAccordeon
  useHTag: true,
  specialGiftcardEvent: undefined,
  isDesktopAccordeon: false,
  isMobileSlider: false,
  isDurationVisible: true,
})

// Dynamic slider components import
const isSlider = computed(() => props.isMobileSlider && isMobile.value)
const productShowcaseContainer = computed(() =>
  isSlider.value ? resolveComponent('ProductsShowcaseSlider') : 'div'
)
const productCard = computed(() =>
  isSlider.value ? resolveComponent('SwiperSlide') : 'div'
)

// Hide loading until components are mounted
const isProductsReady = ref(false)
onMounted(async () => {
  await nextTick()
  isProductsReady.value = true
})

// Product card props in one place
const productCardProps = (item, index) => {
  return {
    dataInsightsObjectId: item.objectID,
    duration: fnGetFormattedDuration(algoliaEventGetters.fnGetDuration(item)),
    durationTitle: t('product.card.duration'),
    image: algoliaEventGetters.getPrincipalImage(item),
    imageLoading: props.imageLoading,
    next: null,
    link: algoliaEventGetters.fnGetLink(item),
    productCategory: algoliaEventGetters.fnGetMainCategory(item),
    productType: '',
    badgeLabel: algoliaEventGetters.getBadgeLabel(item, t),
    rating: algoliaEventGetters.getAverageRating(item, localeBCP47),
    regularPrice: algoliaEventGetters.getPriceAmount(item),
    showAddToCartButton: false,
    style: { '--index': index },
    title: item.title || 'simple-event-showcase',
    alt: algoliaEventGetters.getPrincipalAlt(item),
    wishlistIcon: false,
    isDurationVisible: props.isDurationVisible,
    class: {
      'md:hidden': index + 1 > 4 && isCollapsed.value,
    },
  }
}

// Special giftcard props
const productGiftcardProps = (item) => {
  return {
    dataTestId: 'product-card-giftcard',
    duration: fnGetFormattedDuration(algoliaEventGetters.fnGetDuration(item)),
    image: algoliaEventGetters.getPrincipalImage(item),
    link: `/giftcard/${algoliaEventGetters.fnGetPermalink(item)}/`,
    productCategory: algoliaEventGetters.fnGetMainCategory(item),
    regularPrice: algoliaEventGetters.getPriceAmount(item),
    rating: algoliaEventGetters.getAverageRating(item, localeBCP47),
    title: item.title || 'No title',
    wishlistIcon: false,
    isDurationVisible: false,
  }
}

const fnGetFormattedDuration = (d) => useGetFormattedDuration(d)

const shouldRunSendViewItemList = ref(false)
const localeBCP47 = convertLocaleIntoBCP47(locale.value)

const isCollapsed = ref(props.isDesktopAccordeon)

const productShowcase = ref(null)
const toggleShowMore = () => {
  isCollapsed.value = !isCollapsed.value

  // scroll to top position of container when close show more
  if (isCollapsed.value && productShowcase.value) {
    const header = 116
    const margin = 32
    window.scrollTo({
      top:
        productShowcase.value.getBoundingClientRect().top +
        window.scrollY -
        header -
        margin,
      behavior: 'smooth',
    })
  }
}

// TODO check twice if we really need it
const routerPushToEvent = (object: AlgoliaEventItem, queryId: string) => {
  router.push(
    algoliaEventGetters.getRouterPushToEvent(
      object,
      queryId,
      locale.value,
      route.query.layout === 'sup'
    )
  )
}

const fnHandleClickOnCard = (item: AlgoliaEventItem, i: number) => {
  if (!props.disableSendEventToAlgolia) {
    useSendEventToAlgolia({
      route: algoliaEventGetters.fnGetLink(item),
      objectID: item.objectID,
      index: i + 1,
      algoliaInsightsClient: props.algoliaInsightsClient,
      label: props.algoliaInsightsLabel,
      queryId: props.queryId,
      locale: locale.value,
    })
  }

  routerPushToEvent(item, props.queryId)
  // TODO [GTM] sendSelectListItem(props.gtmItemListId, [item], i);
}

watch(
  () => props.products,
  (newValue) => {
    if (newValue && newValue.length > 0 && props.gtmItemListId) {
      if (process.client) {
        sendViewItemList(props.gtmItemListId, props.products)
      } else {
        shouldRunSendViewItemList.value = true
      }
    }
  },
  { immediate: true }
)
</script>

<style lang="postcss">
.show-more-button {
  @apply w-[300px] h-[45px] self-center mt-4 hidden md:block;
}

.product-showcase-cards-container {
  @apply grid grid-cols-1 md:grid-cols-4 gap-2 md:gap-4;
}

.slider-item {
  @media (min-width: 0px) and (max-width: 320px) {
    width: 85%;
  }

  width: 70%;
  max-width: 240px;
  height: auto;
  display: flex;
}

.fade-slide-up-enter-active,
.fade-slide-up-leave-active {
  transition: transform 0.3s ease;
}

.fade-slide-up-enter-from,
.fade-slide-up-leave-to {
  transform: translateY(30px);
  opacity: 0;
}

.fade-slide-up-leave-active {
  position: absolute;
}
</style>
