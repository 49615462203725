<template>
  <Swiper ref="productsSlider" v-bind="options">
    <slot></slot>
  </Swiper>
</template>

<script setup lang="ts">
const productsSlider = ref(null)
const options = computed(() => {
  return {
    loop: false,
    slidesPerView: 'auto',
    spaceBetween: 8,
    mousewheel: {
      forceToAxis: true,
    },
  }
})
</script>

<style lang="postcss" scoped>
.swiper,
.swiper-wrapper {
  overflow: visible !important;
}
</style>
