const makeToken = () => {
  let token
  try {
    token = window?.localStorage?.getItem(
      'konfetti-algolia-tracking-user-token'
    )
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error accessing local storage', error)
  }

  if (!token || token === 'undefined') {
    token = uuid4()
  }

  return token
}

interface algoliaEventProps {
  route?: string
  objectID: string
  position: string
  algoliaInsightsClient: any
  label: string
  queryId: string
  locale: string
}

export const useSendEventToAlgolia = ({
  route,
  objectID,
  position,
  algoliaInsightsClient,
  label,
  queryId,
  locale,
}: algoliaEventProps) => {
  const token = makeToken()
  if (
    !window?.localStorage ||
    algoliaInsightsClient === null ||
    algoliaInsightsClient === undefined ||
    !token
  )
    return

  algoliaInsightsClient('clickedObjectIDs', {
    userToken: token,
    index: getEventListIndexName(locale),
    eventName: 'Event Clicked - ' + label,
    objectIDs: [objectID],
    positions: [position],
    queryId,
  })
}
